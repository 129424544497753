/* global sssp */
// noinspection JSUnresolvedFunction,JSUnresolvedVariable

import Cookies from 'js-cookie';
import { applyFilters } from '@wordpress/hooks';

class WpifyAds {
	#wpifysl
	#cnAd
	#cnContent
	#halfHourInDays
	#seznamSl
	#adsenseSl
	#notifySeznam

	constructor (positions = []) {
		this.#halfHourInDays = 1 / 24 / 2;
		this.#seznamSl = null;
		this.#adsenseSl = null;
		this.#cnAd = 'wpify-ad';
		this.#cnContent = 'wpify-ad__content';
		this.#wpifysl = {};
		this.#notifySeznam = true;

		['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach((param) => {
			const value = this.#getParam(param) || '';

			if (value.length > 0) {
				Cookies.set(param, value, {
					domain: window.location.domain,
					expires: this.#halfHourInDays,
				});
			}
		});

		this.#loadPositions(positions);
	}

	async #loadPositions (positions) {
		const filteredPositions = await applyFilters('wpify_ads_positions', positions);

		for (const position of filteredPositions) {
			await this.push(position);
		}
	}

	#uid = (length) => {
		let uid = Date.now().toString(36);

		while (uid.length < length) {
			uid = (uid + Math.random().toString(36)).substring(length);
		}

		return uid;
	}

	#getParam = (name, url = window.location.href) => {
		name = name.replace(/[[\]]/g, '\\$&');
		const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
		const results = regex.exec(url);

		if (!results) return null;
		if (!results[2]) return '';

		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	#debug = (...logs) => {
		if (this.#getParam('debug') === '1') {
			console.log(...logs);
		}
	}

	#loadScript = (src, id = null, content = '', attributes = {}, after = null, position = 'beforebegin') => {
		this.#debug('Loading script', { src, id, content, attributes, after });

		if (!id) {
			id = src;
		}

		if (!id) {
			id = this.#uid();
		}

		if (!(this.#wpifysl[id] instanceof Promise)) {
			this.#wpifysl[id] = new Promise((resolve, reject) => {
				const fjs = after || document.getElementsByTagName('script')[0];

				if (document.getElementById(id)) {
					resolve();
				}

				const js = document.createElement('script');
				js.id = id;

				js.addEventListener('load', resolve);
				js.addEventListener('error', e => reject(e.error));

				attributes.src = src || attributes.src;

				Object.keys(attributes).forEach(attribute => {
					if (attribute !== 'src') {
						try {
							js.setAttribute(attribute, attributes[attribute]);
						} catch (e) {}
					}
				});

				if (attributes.src) {
					this.#debug('Loading ', attributes.src);
					js.src = attributes.src;
				}

				fjs.insertAdjacentElement(position, js);

				if (content.trim().length > 0) {
					try {
						js.innerText = content.replace(/\n+/gms, ' ');
					} catch (e) {
						console.error(e);
					}
				}
			});
		}

		return this.#wpifysl[id];
	}

	#setCookie = (name, value, expires = this.#halfHourInDays) => {
		return Cookies.set(name, value, {
			domain: window.location.domain,
			expires,
		});
	}

	#getCookie = (name) => {
		return Cookies.get(name);
	}

	#isSeznamAd = (ad) => {
		return ad.seznam ? parseInt(ad.seznam.zone_id, 10) > 0 : false;
	}

	#isAdSense = (ad) => {
		return ad.adsense ? (ad.adsense.client.length > 0 && ad.adsense.slot.length > 0) : false;
	}

	#isSeznamRef = (sssp) => {
		const displaySeznamAds = this.#getCookie('seznam_referrer');

		if (displaySeznamAds !== 'true' || this.#getParam('utm_source')) {
			this.#setCookie('seznam_referrer', sssp.displaySeznamAds() === true ? 'true' : 'false');
		}

		return this.#getCookie('seznam_referrer') === 'true';
	}

	#isMobile = () => {
		if (document.body.clientWidth < 768) {
			this.#debug('IS MOBILE');
			return true;
		}

		this.#debug('IS DESKTOP');

		return false;
	}

	#filterAds = async (position) => {
		const isFromSeznam = this.#isSeznamRef(window.sssp);
		const isMobile = this.#isMobile();

		const ads = position.ads
			.filter((ad) => (
				(ad.show_to === 'from_seznam' && isFromSeznam)
				|| (ad.show_to === 'not_from_seznam' && !isFromSeznam)
				|| (ad.show_to === 'anyone')
				|| (!ad.show_to)
			))
			.filter((ad) => {
				if (ad.devices && Array.isArray(ad.devices) && ad.devices.length > 0) {
					if (isMobile && ad.devices.includes('mobile')) {
						return true;
					}

					if (!isMobile && ad.devices.includes('non-mobile')) {
						return true;
					}

					return false;
				}

				return true;
			});

		this.#debug('filtering ads at position', {
			isFromSeznam,
			position,
			ads,
		});

		return await applyFilters('wpify_ads_at_position', ads, position);
	}

	#seznamFile = () => {
		if (!this.#seznamSl) {
			this.#debug('loading seznam file ssp');
			this.#seznamSl = this.#loadScript('//ssp.seznam.cz/static/js/ssp.js')
				.then(() => {
					this.#debug('Seznam script loaded');

					if (this.#notifySeznam) {
						this.#notifySeznam = false;

						if (sssp.displaySeznamAds() === true || this.#getCookie('seznam_referrer') === 'true') {
							const event = new CustomEvent('seznam-referrer');
							document.dispatchEvent(event);
						}
					}
				});
		}

		return this.#seznamSl;
	}

	#adsenseFile = () => {
		if (!this.#adsenseSl) {
			this.#debug('loading adsbygoogle');
			this.#adsenseSl = this.#loadScript('//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js');
		}

		(window.adsbygoogle = window.adsbygoogle || []).push({});

		return this.#adsenseSl;
	}

	#seznamAd = (element, ad, position) => {
		if (element.nodeName === 'SCRIPT') {
			const newElement = document.createElement('div');
			element.insertAdjacentElement('beforebegin', newElement);
			element = newElement;
		}

		const id = this.#cnAd + '-' + this.#uid(10);
		element.id = id;

		const adData = {
			zoneId: ad.seznam?.zone_id,
			width: ad.seznam?.width || position.width,
			height: ad.seznam?.height || position.height,
			id,
		};

		if (ad.seznam?.is_popup === 'on') {
			const cookieName = 'seznamPopupShown_' + ad.seznam?.zone_id;
			const adDisplayed = this.#getCookie(cookieName);

			if (adDisplayed !== 'true') {
				const observer = new IntersectionObserver((entries, observer) => {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							const wrapper = document.createElement('div');
							wrapper.classList.add('seznamAd--wrapper');

							const close = document.createElement('div');
							close.classList.add('seznamAd--cButton');
							close.innerText = 'zavřít reklamu';
							close.addEventListener('click', () => {
								document.body.removeChild(wrapper);
							});

							const content = document.createElement('div');
							content.classList.add('content');
							content.id = id + '-content';

							wrapper.appendChild(close);
							wrapper.appendChild(content);
							document.body.appendChild(wrapper);

							adData.id = content.id;
							window.sssp.getAds(adData);
							observer.unobserve(entry.target);
							this.#setCookie(cookieName, 'true');
						}
					});
				});

				observer.observe(element);
			}
		} else {
			window.sssp.getAds(adData);
		}

		const event = new CustomEvent('wpify_seznam_ad_loaded', { detail: { adData, element } });
		document.dispatchEvent(event);
	}

	#htmlAd = (element, ad, position) => {
		const regex = /<script\s*([^>]*)\s*>(.*?)<\/script>/gs;
		const content = ad.html.content.replace(regex, '');

		if (position.raw) {
			element.insertAdjacentHTML('beforebegin', content);
		} else {
			element.insertAdjacentHTML('beforeend', content);
		}

		[...ad.html.content.matchAll(regex)].forEach((script) => {
			const t = document.createElement('div');
			t.innerHTML = script[0];

			const attributes = {};

			for (let i = 0; i < t.firstChild.attributes.length; i++) {
				attributes[t.firstChild.attributes[i].nodeName] = t.firstChild.attributes[i].nodeValue;
			}

			const src = attributes.src;
			const content = script[2];

			this.#debug('Loading script', { attributes, src, content });

			if (src || content) {
				this.#loadScript(src, null, content, attributes, element, position.raw ? 'beforebegin' : 'beforeend');
			}
		});
	}

	#adsenseMarkup = (ad) => {
		if (ad.adsense.width > 0 && ad.adsense.height > 0) {
			return `<ins class="adsbygoogle" style="display:block;width:${ad.adsense.width}px;height:${ad.adsense.height}px;" data-ad-client="${ad.adsense.client}" data-ad-slot="${ad.adsense.slot}" data-ad-width="${ad.adsense.width}" data-ad-height="${ad.adsense.height}"></ins>`;
		} else {
			const format = ad.adsense.format.length ? ad.adsense.format.toString() : 'auto';
			const fullwidth = ad.adsense.fullwidth ? 'true' : 'false';

			return `<ins class="adsbygoogle" style="display:block;" data-ad-client="${ad.adsense.client}" data-ad-slot="${ad.adsense.slot}" data-ad-format="${format}" data-full-width-responsive="${fullwidth}"></ins>`;
		}
	}

	#ad = (element, ad, position) => {
		const load = () => {
			const begin = document.getElementById('begin|' + element.id);
			const end = document.getElementById('end|' + element.id);

			if (position.type !== 'all') {
				let next = begin.nextSibling;

				while (next && next !== end) {
					const toRemove = next;
					next = next.nextSibling;
					toRemove.parentNode.removeChild(toRemove);
				}
			}

			let contentElement = end;

			if (!position.raw) {
				contentElement = document.createElement('div');
				contentElement.classList.add(this.#cnContent);
				end.insertAdjacentElement('beforebegin', contentElement);
			}

			if (position.raw && ad.before.length > 0) {
				contentElement.insertAdjacentHTML('beforebegin', ad.before);
			}

			if (this.#isSeznamAd(ad)) {
				this.#debug('loading seznam ad', ad, position, element, contentElement);
				this.#seznamAd(contentElement, ad, position);
			} else if (this.#isAdSense(ad)) {
				this.#debug('loading adsense ad', ad, position, element, contentElement);
				this.#adsense(contentElement, ad, position);
			} else {
				this.#debug('loading html ad', ad, position, element, contentElement);
				this.#htmlAd(contentElement, ad, position);
			}

			if (position.raw) {
				if (ad.after?.length > 0) {
					contentElement.insertAdjacentHTML('afterend', ad.after);
				}
			} else {
				if (ad.before.length > 0) {
					contentElement.insertAdjacentHTML('afterbegin', ad.before);
				}

				if (ad.after?.length > 0) {
					contentElement.insertAdjacentHTML('beforeend', ad.after);
				}
			}
		}

		if (ad.show_on === 'page_load') {
			this.#debug('loading ad delayed', ad, position, element);
			window.addEventListener('load', load);
		} else {
			this.#debug('loading ad immediately', ad, position, element);
			load();
		}
	}

	#adsense (element, ad, position) {
		if (position.raw) {
			element.insertAdjacentHTML('beforebegin', this.#adsenseMarkup(ad));
		} else {
			element.insertAdjacentHTML('beforeend', this.#adsenseMarkup(ad));
		}

		this.#adsenseFile();
	}

	#firstAd = (element, position) => {
		const ads = position.ads || [];

		if (ads.length > 0) {
			this.#debug('loading first ad', ads[0], position);
			this.#ad(element, ads[0], position);
		} else {
			this.#debug('no ad at the position', position);
			element.parentNode.removeChild(element);
		}
	}

	#randomAd = (element, position) => {
		const ads = position.ads || [];

		if (ads.length > 0) {
			const index = Math.floor(Math.random() * Math.floor(ads.length));
			this.#debug('loading random ad', ads[index], position);
			this.#ad(element, ads[index], position);
		} else {
			this.#debug('no ad at the position', position);
			element.parentNode.removeChild(element);
		}
	}

	#switchAd = (element, position) => {
		const ads = position.ads || [];

		console.log(element);

		if (ads.length > 1) {
			const delay = Math.max((position.switch_interval || 0) * 1000, 1000); // Minimal interval is 1 second
			let interval;
			let index = 0;

			const show = () => {
				this.#debug('loading switch ad', ads[index], position);
				this.#ad(element, ads[index], position);
				index = (index + 1) % ads.length;
			};

			const start = () => {
				interval = window.setInterval(show, delay);
			};

			const stop = () => {
				window.clearInterval(interval);
			};

			element.addEventListener('mouseover', stop);
			element.addEventListener('mouseout', start);

			start();
			show();
		} else {
			this.#debug('less than two ads at the position', position);
			this.#firstAd(element, position);
		}
	}

	#allAds = (element, position) => {
		for (let i = (position.ads.length - 1); i >= 0; i--) {
			this.#ad(element, position.ads[i], position);
		}
	}

	push = async (position) => {
		const id = position.script_id;
		const element = document.getElementById(id);
		this.#debug('loading position', position, element);
		await this.#seznamFile();
		position.ads = await this.#filterAds(position);

		if (position.ads.length === 0) {
			this.#debug('not a valid position', id);
			element.parentNode.removeChild(element);
		} else {
			switch (position.type) {
				case 'first':
					this.#debug('loading first position', position, element);
					this.#firstAd(element, position);
					break;
				case 'switch':
					this.#debug('loading switch position', position, element);
					this.#switchAd(element, position);
					break;
				case 'all':
					this.#debug('loading all ads on position', position, element);
					this.#allAds(element, position);
					break;
				default:
					this.#debug('loading random position', position, element);
					this.#randomAd(element, position);
			}
		}
	}
}

window.WpifyAds = new WpifyAds(window.WpifyAds);
